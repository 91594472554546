/*!
 * 
 * ant-design-pro v2.3.1
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-charts-chart-card-chartCard {
  position: relative; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-chartTop {
  position: relative;
  width: 100%;
  overflow: hidden; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-chartTopMargin {
  margin-bottom: 12px; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-chartTopHasMargin {
  margin-bottom: 20px; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-metaWrap {
  float: left; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-avatar {
  position: relative;
  top: 4px;
  float: left;
  margin-right: 20px; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-avatar img {
  border-radius: 100%; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-meta {
  height: 22px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-action {
  position: absolute;
  top: 4px;
  right: 0;
  line-height: 1;
  cursor: pointer; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-total {
  height: 38px;
  margin-top: 4px;
  margin-bottom: 0;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 30px;
  line-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-content {
  position: relative;
  width: 100%;
  margin-bottom: 12px; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-contentFixed {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-footer {
  margin-top: 8px;
  padding-top: 9px;
  border-top: 1px solid #e8e8e8; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-footer > * {
  position: relative; }

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-footerMargin {
  margin-top: 20px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-charts-field-field {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.antd-pro-charts-field-field .antd-pro-charts-field-label,
.antd-pro-charts-field-field .antd-pro-charts-field-number {
  font-size: 14px;
  line-height: 22px; }

.antd-pro-charts-field-field .antd-pro-charts-field-number {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85); }

.antd-pro-charts-miniChart {
  position: relative;
  width: 100%; }

.antd-pro-charts-miniChart .antd-pro-charts-chartContent {
  position: absolute;
  bottom: -28px;
  width: 100%; }

.antd-pro-charts-miniChart .antd-pro-charts-chartContent > div {
  margin: 0 -5px;
  overflow: hidden; }

.antd-pro-charts-miniChart .antd-pro-charts-chartLoading {
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -7px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-charts-pie-pie {
  position: relative; }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-chart {
  position: relative; }

.antd-pro-charts-pie-pie.antd-pro-charts-pie-hasLegend .antd-pro-charts-pie-chart {
  width: calc(100% - 240px); }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-legend {
  position: absolute;
  top: 50%;
  right: 0;
  min-width: 200px;
  margin: 0 20px;
  padding: 0;
  list-style: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-legend li {
  height: 22px;
  margin-bottom: 16px;
  line-height: 22px;
  cursor: pointer; }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-legend li:last-child {
  margin-bottom: 0; }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 8px; }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-line {
  display: inline-block;
  width: 1px;
  height: 16px;
  margin-right: 8px;
  background-color: #e8e8e8; }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-legendTitle {
  color: rgba(0, 0, 0, 0.65); }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-percent {
  color: rgba(0, 0, 0, 0.45); }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-value {
  position: absolute;
  right: 0; }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-title {
  margin-bottom: 8px; }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-total {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 62px;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-total > h4 {
  height: 22px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px; }

.antd-pro-charts-pie-pie .antd-pro-charts-pie-total > p {
  display: block;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.2em;
  line-height: 32px;
  white-space: nowrap; }

.antd-pro-charts-pie-legendBlock.antd-pro-charts-pie-hasLegend .antd-pro-charts-pie-chart {
  width: 100%;
  margin: 0 0 32px 0; }

.antd-pro-charts-pie-legendBlock .antd-pro-charts-pie-legend {
  position: relative;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-charts-radar-radar .antd-pro-charts-radar-legend {
  margin-top: 16px; }

.antd-pro-charts-radar-radar .antd-pro-charts-radar-legend .antd-pro-charts-radar-legendItem {
  position: relative;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  text-align: center;
  cursor: pointer; }

.antd-pro-charts-radar-radar .antd-pro-charts-radar-legend .antd-pro-charts-radar-legendItem p {
  margin: 0; }

.antd-pro-charts-radar-radar .antd-pro-charts-radar-legend .antd-pro-charts-radar-legendItem h6 {
  margin-top: 4px;
  margin-bottom: 0;
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 32px; }

.antd-pro-charts-radar-radar .antd-pro-charts-radar-legend .antd-pro-charts-radar-legendItem::after {
  position: absolute;
  top: 8px;
  right: 0;
  width: 1px;
  height: 40px;
  background-color: #e8e8e8;
  content: ''; }

.antd-pro-charts-radar-radar .antd-pro-charts-radar-legend > :last-child .antd-pro-charts-radar-legendItem::after {
  display: none; }

.antd-pro-charts-radar-radar .antd-pro-charts-radar-legend .antd-pro-charts-radar-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 6px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-charts-mini-progress-miniProgress {
  position: relative;
  width: 100%;
  padding: 5px 0; }

.antd-pro-charts-mini-progress-miniProgress .antd-pro-charts-mini-progress-progressWrap {
  position: relative;
  background-color: #f5f5f5; }

.antd-pro-charts-mini-progress-miniProgress .antd-pro-charts-mini-progress-progress {
  width: 0;
  height: 100%;
  background-color: #1890ff;
  border-radius: 1px 0 0 1px;
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s; }

.antd-pro-charts-mini-progress-miniProgress .antd-pro-charts-mini-progress-target {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 9;
  width: 20px; }

.antd-pro-charts-mini-progress-miniProgress .antd-pro-charts-mini-progress-target span {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 4px;
  border-radius: 100px; }

.antd-pro-charts-mini-progress-miniProgress .antd-pro-charts-mini-progress-target span:last-child {
  top: auto;
  bottom: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-charts-water-wave-waterWave {
  position: relative;
  display: inline-block;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left; }

.antd-pro-charts-water-wave-waterWave .antd-pro-charts-water-wave-text {
  position: absolute;
  top: 32px;
  left: 0;
  width: 100%;
  text-align: center; }

.antd-pro-charts-water-wave-waterWave .antd-pro-charts-water-wave-text span {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px; }

.antd-pro-charts-water-wave-waterWave .antd-pro-charts-water-wave-text h4 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 32px; }

.antd-pro-charts-water-wave-waterWave .antd-pro-charts-water-wave-waterWaveCanvasWrapper {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.antd-pro-charts-tag-cloud-tagCloud {
  overflow: hidden; }

.antd-pro-charts-tag-cloud-tagCloud canvas {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.antd-pro-charts-timeline-chart-timelineChart {
  background: #fff; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-description-list-descriptionList .ant-row {
  margin-bottom: -16px;
  overflow: hidden; }

.antd-pro-description-list-descriptionList + .antd-pro-description-list-descriptionList .ant-row {
  margin-top: 16px; }

.antd-pro-description-list-descriptionList .antd-pro-description-list-title {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 14px; }

.antd-pro-description-list-descriptionList .antd-pro-description-list-term {
  display: table-cell;
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  white-space: nowrap; }

.antd-pro-description-list-descriptionList .antd-pro-description-list-term::after {
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  content: ':'; }

.antd-pro-description-list-descriptionList .antd-pro-description-list-detail {
  display: table-cell;
  width: 100%;
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 20px; }

.antd-pro-description-list-descriptionList.antd-pro-description-list-small .ant-row {
  margin-bottom: -8px; }

.antd-pro-description-list-descriptionList.antd-pro-description-list-small + .antd-pro-description-list-descriptionList .ant-row {
  margin-top: 8px; }

.antd-pro-description-list-descriptionList.antd-pro-description-list-small .antd-pro-description-list-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.65); }

.antd-pro-description-list-descriptionList.antd-pro-description-list-small .antd-pro-description-list-term,
.antd-pro-description-list-descriptionList.antd-pro-description-list-small .antd-pro-description-list-detail {
  padding-bottom: 8px; }

.antd-pro-description-list-descriptionList.antd-pro-description-list-large .antd-pro-description-list-title {
  font-size: 16px; }

.antd-pro-description-list-descriptionList.antd-pro-description-list-vertical .antd-pro-description-list-term {
  display: block;
  padding-bottom: 8px; }

.antd-pro-description-list-descriptionList.antd-pro-description-list-vertical .antd-pro-description-list-detail {
  display: block; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-exception-exception {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 80%;
  min-height: 500px; }

.antd-pro-exception-exception .antd-pro-exception-imgBlock {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 62.5%;
  -ms-flex: 0 0 62.5%;
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1; }

.antd-pro-exception-exception .antd-pro-exception-imgBlock::before,
.antd-pro-exception-exception .antd-pro-exception-imgBlock::after {
  content: ' ';
  display: table; }

.antd-pro-exception-exception .antd-pro-exception-imgBlock::after {
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden; }

.antd-pro-exception-exception .antd-pro-exception-imgEle {
  float: right;
  width: 100%;
  max-width: 430px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain; }

.antd-pro-exception-exception .antd-pro-exception-content {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto; }

.antd-pro-exception-exception .antd-pro-exception-content h1 {
  margin-bottom: 24px;
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px; }

.antd-pro-exception-exception .antd-pro-exception-content .antd-pro-exception-desc {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px; }

.antd-pro-exception-exception .antd-pro-exception-content .antd-pro-exception-actions button:not(:last-child) {
  margin-right: 8px; }

@media screen and (max-width: 1200px) {
  .antd-pro-exception-exception .antd-pro-exception-imgBlock {
    padding-right: 88px; } }

@media screen and (max-width: 576px) {
  .antd-pro-exception-exception {
    display: block;
    text-align: center; }
  .antd-pro-exception-exception .antd-pro-exception-imgBlock {
    margin: 0 auto 24px;
    padding-right: 0; } }

@media screen and (max-width: 480px) {
  .antd-pro-exception-exception .antd-pro-exception-imgBlock {
    margin-bottom: -24px;
    overflow: hidden; } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-footer-toolbar-toolbar {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  height: 56px;
  padding: 0 24px;
  line-height: 56px;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  -webkit-box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03); }

.antd-pro-footer-toolbar-toolbar::after {
  display: block;
  clear: both;
  content: ''; }

.antd-pro-footer-toolbar-toolbar .antd-pro-footer-toolbar-left {
  float: left; }

.antd-pro-footer-toolbar-toolbar .antd-pro-footer-toolbar-right {
  float: right; }

.antd-pro-footer-toolbar-toolbar button + button {
  margin-left: 8px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-global-footer-globalFooter {
  margin: 48px 0 24px 0;
  padding: 0 16px;
  text-align: center; }

.antd-pro-global-footer-globalFooter .antd-pro-global-footer-links {
  margin-bottom: 8px; }

.antd-pro-global-footer-globalFooter .antd-pro-global-footer-links a {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.antd-pro-global-footer-globalFooter .antd-pro-global-footer-links a:not(:last-child) {
  margin-right: 40px; }

.antd-pro-global-footer-globalFooter .antd-pro-global-footer-links a:hover {
  color: rgba(0, 0, 0, 0.65); }

.antd-pro-global-footer-globalFooter .antd-pro-global-footer-copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-header-search-headerSearch .anticon-search {
  font-size: 16px;
  cursor: pointer; }

.antd-pro-header-search-headerSearch .antd-pro-header-search-input {
  width: 0;
  background: transparent;
  border-radius: 0;
  -webkit-transition: width 0.3s, margin-left 0.3s;
  transition: width 0.3s, margin-left 0.3s; }

.antd-pro-header-search-headerSearch .antd-pro-header-search-input .ant-select-selection {
  background: transparent; }

.antd-pro-header-search-headerSearch .antd-pro-header-search-input input {
  padding-right: 0;
  padding-left: 0;
  border: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.antd-pro-header-search-headerSearch .antd-pro-header-search-input,
.antd-pro-header-search-headerSearch .antd-pro-header-search-input:hover,
.antd-pro-header-search-headerSearch .antd-pro-header-search-input:focus {
  border-bottom: 1px solid #d9d9d9; }

.antd-pro-header-search-headerSearch .antd-pro-header-search-input.antd-pro-header-search-show {
  width: 210px;
  margin-left: 8px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-header-dropdown-container > * {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }

@media screen and (max-width: 480px) {
  .antd-pro-header-dropdown-container {
    width: 100% !important; }
  .antd-pro-header-dropdown-container > * {
    border-radius: 0 !important; } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-notice-icon-list {
  max-height: 400px;
  overflow: auto; }

.antd-pro-notice-icon-list::-webkit-scrollbar {
  display: none; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item {
  padding-right: 24px;
  padding-left: 24px;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item .antd-pro-notice-icon-meta {
  width: 100%; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item .antd-pro-notice-icon-avatar {
  margin-top: 4px;
  background: #fff; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item .antd-pro-notice-icon-iconElement {
  font-size: 32px; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item.antd-pro-notice-icon-read {
  opacity: 0.4; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item:last-child {
  border-bottom: 0; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item:hover {
  background: #e6f7ff; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item .antd-pro-notice-icon-title {
  margin-bottom: 8px;
  font-weight: normal; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item .antd-pro-notice-icon-description {
  font-size: 12px;
  line-height: 1.5; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item .antd-pro-notice-icon-datetime {
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.5; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-item .antd-pro-notice-icon-extra {
  float: right;
  margin-top: -1.5px;
  margin-right: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-loadMore {
  padding: 8px 0;
  color: #1890ff;
  text-align: center;
  cursor: pointer; }

.antd-pro-notice-icon-list .antd-pro-notice-icon-loadMore.antd-pro-notice-icon-loadedAll {
  color: rgba(0, 0, 0, 0.25);
  cursor: unset; }

.antd-pro-notice-icon-notFound {
  padding: 73px 0 88px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center; }

.antd-pro-notice-icon-notFound img {
  display: inline-block;
  height: 76px;
  margin-bottom: 16px; }

.antd-pro-notice-icon-bottomBar {
  height: 46px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 46px;
  text-align: center;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.antd-pro-notice-icon-bottomBar div {
  display: inline-block;
  width: 50%;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.antd-pro-notice-icon-bottomBar div:hover {
  color: rgba(0, 0, 0, 0.85); }

.antd-pro-notice-icon-bottomBar div:only-child {
  width: 100%; }

.antd-pro-notice-icon-bottomBar div:not(:only-child):last-child {
  border-left: 1px solid #e8e8e8; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-notice-icon-popover {
  position: relative;
  width: 336px; }

.antd-pro-notice-icon-noticeButton {
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.antd-pro-notice-icon-icon {
  padding: 4px;
  vertical-align: middle; }

.antd-pro-notice-icon-badge {
  font-size: 16px; }

.antd-pro-notice-icon-tabs .ant-tabs-nav-scroll {
  text-align: center; }

.antd-pro-notice-icon-tabs .ant-tabs-bar {
  margin-bottom: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-page-header-pageHeader {
  padding: 16px 32px 0 32px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-wide {
  max-width: 1200px;
  margin: auto; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-breadcrumb {
  margin-bottom: 16px; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-tabs {
  margin: 0 0 0 -8px; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-tabs .ant-tabs-bar {
  margin-bottom: 1px;
  border-bottom: none; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-logo {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin-right: 16px;
  padding-top: 1px; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-logo > img {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 4px; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 20px; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-action {
  min-width: 266px;
  margin-left: 56px; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-action .ant-btn-group:not(:last-child),
.antd-pro-page-header-pageHeader .antd-pro-page-header-action .ant-btn:not(:last-child) {
  margin-right: 8px; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-action .ant-btn-group > .ant-btn {
  margin-right: 0; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-title,
.antd-pro-page-header-pageHeader .antd-pro-page-header-content {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-action,
.antd-pro-page-header-pageHeader .antd-pro-page-header-extraContent,
.antd-pro-page-header-pageHeader .antd-pro-page-header-main {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-main {
  width: 100%; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-title,
.antd-pro-page-header-pageHeader .antd-pro-page-header-action {
  margin-bottom: 16px; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-logo,
.antd-pro-page-header-pageHeader .antd-pro-page-header-content,
.antd-pro-page-header-pageHeader .antd-pro-page-header-extraContent {
  margin-bottom: 16px; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-action,
.antd-pro-page-header-pageHeader .antd-pro-page-header-extraContent {
  text-align: right; }

.antd-pro-page-header-pageHeader .antd-pro-page-header-extraContent {
  min-width: 242px;
  margin-left: 88px; }

@media screen and (max-width: 1200px) {
  .antd-pro-page-header-pageHeader .antd-pro-page-header-extraContent {
    margin-left: 44px; } }

@media screen and (max-width: 992px) {
  .antd-pro-page-header-pageHeader .antd-pro-page-header-extraContent {
    margin-left: 20px; } }

@media screen and (max-width: 768px) {
  .antd-pro-page-header-pageHeader .antd-pro-page-header-row {
    display: block; }
  .antd-pro-page-header-pageHeader .antd-pro-page-header-action,
  .antd-pro-page-header-pageHeader .antd-pro-page-header-extraContent {
    margin-left: 0;
    text-align: left; } }

@media screen and (max-width: 576px) {
  .antd-pro-page-header-pageHeader .antd-pro-page-header-detail {
    display: block; } }

@media screen and (max-width: 480px) {
  .antd-pro-page-header-pageHeader .antd-pro-page-header-action .ant-btn-group,
  .antd-pro-page-header-pageHeader .antd-pro-page-header-action .ant-btn {
    display: block;
    margin-bottom: 8px; }
  .antd-pro-page-header-pageHeader .antd-pro-page-header-action .ant-btn-group > .ant-btn {
    display: inline-block;
    margin-bottom: 0; } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-result-result {
  width: 72%;
  margin: 0 auto;
  text-align: center; }

@media screen and (max-width: 480px) {
  .antd-pro-result-result {
    width: 100%; } }

.antd-pro-result-result .antd-pro-result-icon {
  margin-bottom: 24px;
  font-size: 72px;
  line-height: 72px; }

.antd-pro-result-result .antd-pro-result-icon > .antd-pro-result-success {
  color: #52c41a; }

.antd-pro-result-result .antd-pro-result-icon > .antd-pro-result-error {
  color: #f5222d; }

.antd-pro-result-result .antd-pro-result-title {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px; }

.antd-pro-result-result .antd-pro-result-description {
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px; }

.antd-pro-result-result .antd-pro-result-extra {
  padding: 24px 40px;
  text-align: left;
  background: #fafafa;
  border-radius: 2px; }

@media screen and (max-width: 480px) {
  .antd-pro-result-result .antd-pro-result-extra {
    padding: 18px 20px; } }

.antd-pro-result-result .antd-pro-result-actions {
  margin-top: 32px; }

.antd-pro-result-result .antd-pro-result-actions button:not(:last-child) {
  margin-right: 8px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-tag-select-tagSelect {
  position: relative;
  max-height: 32px;
  margin-left: -8px;
  overflow: hidden;
  line-height: 32px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.antd-pro-tag-select-tagSelect .ant-tag {
  margin-right: 24px;
  padding: 0 8px;
  font-size: 14px; }

.antd-pro-tag-select-tagSelect.antd-pro-tag-select-expanded {
  max-height: 200px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.antd-pro-tag-select-tagSelect .antd-pro-tag-select-trigger {
  position: absolute;
  top: 0;
  right: 0; }

.antd-pro-tag-select-tagSelect .antd-pro-tag-select-trigger i {
  font-size: 12px; }

.antd-pro-tag-select-tagSelect.antd-pro-tag-select-hasExpandTag {
  padding-right: 50px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-avatar-list-avatarList {
  display: inline-block; }

.antd-pro-avatar-list-avatarList ul {
  display: inline-block;
  margin-left: 8px;
  font-size: 0; }

.antd-pro-avatar-list-avatarItem {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-left: -8px;
  font-size: 14px; }

.antd-pro-avatar-list-avatarItem .ant-avatar {
  border: 1px solid #fff; }

.antd-pro-avatar-list-avatarItemLarge {
  width: 40px;
  height: 40px; }

.antd-pro-avatar-list-avatarItemSmall {
  width: 24px;
  height: 24px; }

.antd-pro-avatar-list-avatarItemMini {
  width: 20px;
  height: 20px; }

.antd-pro-avatar-list-avatarItemMini .ant-avatar {
  width: 20px;
  height: 20px;
  line-height: 20px; }

.antd-pro-avatar-list-avatarItemMini .ant-avatar .ant-avatar-string {
  font-size: 12px;
  line-height: 18px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-trend-trendItem {
  display: inline-block;
  font-size: 14px;
  line-height: 22px; }

.antd-pro-trend-trendItem .antd-pro-trend-up,
.antd-pro-trend-trendItem .antd-pro-trend-down {
  position: relative;
  top: 1px;
  margin-left: 4px; }

.antd-pro-trend-trendItem .antd-pro-trend-up i,
.antd-pro-trend-trendItem .antd-pro-trend-down i {
  font-size: 12px;
  -webkit-transform: scale(0.83);
  -ms-transform: scale(0.83);
  transform: scale(0.83); }

.antd-pro-trend-trendItem .antd-pro-trend-up {
  color: #f5222d; }

.antd-pro-trend-trendItem .antd-pro-trend-down {
  top: -1px;
  color: #52c41a; }

.antd-pro-trend-trendItem.antd-pro-trend-trendItemGrey .antd-pro-trend-up,
.antd-pro-trend-trendItem.antd-pro-trend-trendItemGrey .antd-pro-trend-down {
  color: rgba(0, 0, 0, 0.65); }

.antd-pro-trend-trendItem.antd-pro-trend-reverseColor .antd-pro-trend-up {
  color: #52c41a; }

.antd-pro-trend-trendItem.antd-pro-trend-reverseColor .antd-pro-trend-down {
  color: #f5222d; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-number-info-numberInfo .antd-pro-number-info-suffix {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  font-style: normal; }

.antd-pro-number-info-numberInfo .antd-pro-number-info-numberInfoTitle {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.antd-pro-number-info-numberInfo .antd-pro-number-info-numberInfoSubTitle {
  height: 22px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all; }

.antd-pro-number-info-numberInfo .antd-pro-number-info-numberInfoValue {
  margin-top: 4px;
  overflow: hidden;
  font-size: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all; }

.antd-pro-number-info-numberInfo .antd-pro-number-info-numberInfoValue > span {
  display: inline-block;
  height: 32px;
  margin-right: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 32px; }

.antd-pro-number-info-numberInfo .antd-pro-number-info-numberInfoValue .antd-pro-number-info-subTotal {
  margin-right: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  vertical-align: top; }

.antd-pro-number-info-numberInfo .antd-pro-number-info-numberInfoValue .antd-pro-number-info-subTotal i {
  margin-left: 4px;
  font-size: 12px;
  -webkit-transform: scale(0.82);
  -ms-transform: scale(0.82);
  transform: scale(0.82); }

.antd-pro-number-info-numberInfo .antd-pro-number-info-numberInfoValue .antd-pro-number-info-subTotal .anticon-caret-up {
  color: #f5222d; }

.antd-pro-number-info-numberInfo .antd-pro-number-info-numberInfoValue .antd-pro-number-info-subTotal .anticon-caret-down {
  color: #52c41a; }

.antd-pro-number-info-numberInfolight .antd-pro-number-info-numberInfoValue > span {
  color: rgba(0, 0, 0, 0.65); }

.antd-pro-ellipsis-ellipsis {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  word-break: break-all; }

.antd-pro-ellipsis-lines {
  position: relative; }

.antd-pro-ellipsis-lines .antd-pro-ellipsis-shadow {
  position: absolute;
  z-index: -999;
  display: block;
  color: transparent;
  opacity: 0; }

.antd-pro-ellipsis-lineClamp {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-login-login .ant-tabs .ant-tabs-bar {
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 0; }

.antd-pro-login-login .ant-form-item {
  margin: 0 2px 24px; }

.antd-pro-login-login .antd-pro-login-getCaptcha {
  display: block;
  width: 100%; }

.antd-pro-login-login .antd-pro-login-icon {
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.antd-pro-login-login .antd-pro-login-icon:hover {
  color: #1890ff; }

.antd-pro-login-login .antd-pro-login-other {
  margin-top: 24px;
  line-height: 22px;
  text-align: left; }

.antd-pro-login-login .antd-pro-login-other .antd-pro-login-register {
  float: right; }

.antd-pro-login-login .antd-pro-login-prefixIcon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px; }

.antd-pro-login-login .antd-pro-login-submit {
  width: 100%;
  margin-top: 24px; }

/*# sourceMappingURL=ant-design-pro.css.map*/
