div.chart-holder {
  position: relative; }

.chart-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: "IBM Plex Sans", Arial, sans-serif; }
  .chart-wrapper svg.chart-svg {
    overflow: visible !important;
    /*
		Axes
		*/ }
    .chart-wrapper svg.chart-svg g.yAxes.axis path.domain {
      stroke: none; }
    .chart-wrapper svg.chart-svg g.x.axis path.domain {
      stroke: #959595;
      stroke-width: 2; }
    .chart-wrapper svg.chart-svg g.x.grid g.tick line, .chart-wrapper svg.chart-svg g.y.grid g.tick line {
      stroke: #eceeef; }
    .chart-wrapper svg.chart-svg path.line {
      fill: none;
      stroke-width: 2;
      mix-blend-mode: multiply; }
      .chart-wrapper svg.chart-svg path.line:hover {
        cursor: default; }
    .chart-wrapper svg.chart-svg circle.dot {
      stroke-width: 1.5; }
  .chart-wrapper text {
    font-size: 12px;
    fill: #595859;
    stroke: #595859;
    stroke-width: .3;
    font-family: "IBM Plex Sans", Arial, sans-serif; }
    .chart-wrapper text.title {
      font-size: 18px;
      font-weight: bold; }
    .chart-wrapper text.donut-figure {
      font-size: 24px;
      font-weight: normal;
      fill: #595859; }
    .chart-wrapper text.donut-title {
      font-size: 15px;
      fill: #595859; }
  .chart-wrapper .legend-wrapper {
    height: 40px;
    width: 100%; }
  .chart-wrapper .legend-btn.clickable:hover {
    cursor: pointer; }
  .chart-wrapper .legend-btn.clickable .legend-circle:hover {
    cursor: pointer; }
  .chart-wrapper .legend {
    float: right;
    margin-top: 0;
    margin-right: 20px;
    color: #586464;
    overflow: hidden;
    padding-left: 0;
    white-space: nowrap; }
    .chart-wrapper .legend .expand-btn {
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;utf8,<svg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><!-- Generator: Sketch 46.2 (44496) - http://www.bohemiancoding.com/sketch --><title>icoPlus</title><desc>Created with Sketch.</desc><defs></defs><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='Artboard' transform='translate(-496.000000, -162.000000)'><g id='icoPlus' transform='translate(496.000000, 162.000000)'><g id='open-modal-' fill='%234178BE'><rect id='Rectangle' x='0' y='0' width='13' height='13'></rect><g id='Expand-20px' fill-rule='nonzero'><path d='M12.1875,12.1875 L0.8125,12.1875 L0.8125,0.8125 L12.1875,0.8125 L12.1875,12.1875 Z M13,0 L0,0 L0,13 L13,13 L13,0 Z' id='Shape'></path></g></g><g id='Add,-Plus-16px' transform='translate(2.000000, 2.000000)' fill-rule='nonzero' fill='%23FFFFFF'><polygon id='Shape' points='9 3.85714286 5.14285714 3.85714286 5.14285714 0 3.85714286 0 3.85714286 3.85714286 0 3.85714286 0 5.14285714 3.85714286 5.14285714 3.85714286 9 5.14285714 9 5.14285714 5.14285714 9 5.14285714'></polygon></g></g></g></g></svg>");
      position: absolute;
      right: 0;
      top: 2px;
      height: 16px;
      width: 16px;
      cursor: pointer; }
  .chart-wrapper .legend-btn {
    display: inline-block;
    line-height: 20px;
    font-size: 12px;
    margin-bottom: 0; }
    .chart-wrapper .legend-btn .legend-circle {
      display: inherit;
      height: 12px;
      width: 12px;
      border-radius: 3px;
      margin: 0 5px 5px 15px;
      box-sizing: border-box; }
    .chart-wrapper .legend-btn span {
      display: inline; }
    .chart-wrapper .legend-btn text {
      font-size: 12px;
      max-width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inherit; }
    .chart-wrapper .legend-btn.not-allowed {
      cursor: default; }
      .chart-wrapper .legend-btn.not-allowed .legend-circle:hover {
        cursor: default;
        box-shadow: none !important; }
  .chart-wrapper .legend-tooltip {
    padding: 0;
    border: none; }
    .chart-wrapper .legend-tooltip .legend-tooltip-header {
      color: white;
      font-size: 15px;
      font-weight: 700;
      padding: 13px 20px;
      margin: 0; }
    .chart-wrapper .legend-tooltip .legend-btn {
      display: inline-block;
      width: 100%; }
    .chart-wrapper .legend-tooltip text {
      max-width: 145px; }
    .chart-wrapper .legend-tooltip button {
      position: absolute;
      top: 7px;
      right: 10px; }
    .chart-wrapper .legend-tooltip .legend-tooltip-content {
      text-align: left;
      padding: 0 7px 15px 7px; }
  .chart-wrapper .right-legend {
    position: absolute;
    right: 0;
    overflow: auto;
    margin: 0;
    max-width: 30%;
    padding-left: 30px;
    white-space: inherit; }
    .chart-wrapper .right-legend text {
      padding-left: 15px; }
    .chart-wrapper .right-legend .legend-btn {
      width: 100%;
      text-indent: -.7em; }
      .chart-wrapper .right-legend .legend-btn text {
        max-width: 90%; }
    .chart-wrapper .right-legend .legend-circle {
      margin: 5px; }

div.chart-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75); }
  div.chart-overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  div.chart-overlay div.ccharts-overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    div.chart-overlay div.ccharts-overlay-content div.bx--loading {
      margin: auto; }

.tooltip {
  background-color: #fff;
  border: 1px solid #DFE3E6;
  padding: .25rem .5rem;
  display: block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  font-family: "IBM Plex Sans", Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  padding: 10px;
  border-radius: 3px;
  min-width: 80px;
  max-width: 70%;
  word-wrap: break-word;
  z-index: 1059; }
  .tooltip p {
    margin: 0;
    font-size: 12px; }
    .tooltip p.bignum {
      font-size: 19px;
      font-weight: 600; }
  .tooltip b {
    font-weight: 600; }

#carbon-charts-patterns svg {
  height: 0; }
